const btnPrev = document.querySelector('.page-num__left');
const btnNext = document.querySelector('.page-num__right');

// Postavljam page= na kraju query string-a

export function prevP(){
    if(btnPrev){
        btnPrev.addEventListener('click', function(e){
            e.preventDefault();
            correctPage(-1);
        });
    }
}


export function nextP(){
    if(btnNext){
        btnNext.addEventListener('click', function(e){
            e.preventDefault();
            correctPage(1);
        });
    }
}


function correctPage(add){
    const currentPage = document.querySelector('.page-num__text').dataset.current_page;
    let url = window.location.href;
    const original = `strana=${currentPage * 1 + add}`;

    url = createPageUrl(url, original);
    window.location.href = url;
}


function createPageUrl(url, original){

    // 1 - Strana slicni proizvodi
    if(url.includes('slicni-proizvodi')){

        // A - query string ne sadrzi 'strana', ni drugi query string, 'sortiranje'
        if(!url.includes('strana') && !url.includes('sortiranje')){
            return url = `${url}?${original}`;
        }

        // ⏳ B - query string ne sadrzi 'strana', a sadrzi drugi query string, 'sortiranje'
        if(!url.includes('strana') && url.includes('sortiranje')){
            return url = `${url}&${original}`;
        }

        // C - na kraju '?strana' ili '&strana'
        if(url.includes('?strana')){
            var urlNiz = url.split('?');
            var visak = urlNiz[urlNiz.length - 1];
            return url = url.replace(visak, original);
        }

        if(url.includes('&strana')){
            var urlNiz = url.split('&');
            var visak = urlNiz[urlNiz.length - 1];
            return url = url.replace(visak, original);
        }

    } else {
        // 2 - Strana tyres, kao rezultat potvrde konfiguratora

        // A - query string ne sadrzi strana
        if(!url.includes('strana')){
            return url = `${url}&${original}`;
        }

        // B - na kraju &strana=
        if(url.includes('&strana')){
            var urlNiz = url.split('&');
            var visak = urlNiz[urlNiz.length - 1];
            return url = url.replace(visak, original);
        }

    }

};

