// import axios from 'axios';
// const axios = require('../../node_modules/axios/dist/browser/axios.cjs');

import { hideAlert, showAlert } from './alert.js';



export function addToCartTyrePage(){
    // let url = location.href;
    let url = '/korpa';
    const addBtn = document.querySelector('.cart-qt-box .confirm-button');
    const qtBtn = document.querySelector('.cart-qt-box input');

    addBtn.addEventListener('click', async function(e){
        e.preventDefault();
        let addQuantity;
        addQuantity = qtBtn.value * 1;

        const product = {
            id: addBtn.dataset.id,
            naziv: addBtn.dataset.naziv,
            proizvodjac: addBtn.dataset.proizvodjac,
            model: addBtn.dataset.model,
            cena: addBtn.dataset.cena,
            cenaNaPopustu: addBtn.dataset.cena_na_popustu,
            sezona:addBtn.dataset.sezona,
            sirina: addBtn.dataset.sirina,
            visina: addBtn.dataset.visina,
            precnik: addBtn.dataset.precnik,
            slika: addBtn.dataset.slika
        }

        try {
            await axios({
                method: 'post',
                url: `/cart`,
                data: {
                    product,
                    addQuantity
                }
            }
        );
        location.assign(url);
        
        } catch(err) {
            console.log(err);
        }
    });

}


export function goToCart() {
    const cartBtn = document.querySelector('.header__icon--cart path');
    const cartQt = document.querySelector('.cart-number').textContent;
    cartBtn.addEventListener('click', () => {
        if (!cartQt || cartQt === '0') return showAlert('error', 'Vaša korpa je prazna.');
        location.href = '/korpa';
    }); 
}


export function increaseInCart(){
    let url = location.href;
    const signBtns = document.querySelectorAll('.quantity');

    for(let i = 0; i < signBtns.length; i++){
        signBtns[i].addEventListener('click', async function(e){
            const productData = e.target.closest('.quantity');

            // Formiranje proizvoda
            const product = {
                id: productData.dataset.id,
                naziv: productData.dataset.naziv,
                proizvodjac: productData.dataset.proizvodjac,
                model: productData.dataset.model,
                cena: productData.dataset.cena,
                cenaNaPopustu: productData.dataset.cena_na_popustu,
                sezona:productData.dataset.sezona,
                sirina: productData.dataset.sirina,
                visina: productData.dataset.visina,
                precnik: productData.dataset.precnik,
                slika: productData.dataset.slika
            }
            // console.log('🏈 product: ', product);

            // 1 - Klik na plus
            if(e.target.closest('.quantity__sign--plus')){
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            // 2 - Klik na minus
            } else if (e.target.closest('.quantity__sign--minus')){
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product,
                            addQuantity: -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            }
            
        });
    }
    
}


export function deleteItem(){
    let url = location.href;
    const removeBtns = document.querySelectorAll('.remove');

    for (let i = 1; i < removeBtns.length; i++ ){

        // Klik na kantu
        removeBtns[i].addEventListener('click', async function(e){
            if(e.target.closest('.remove')){
                const target = e.target.closest('.remove');
                const oldQuantity = target.parentElement.children[4].children[1].textContent * 1;

                // Formiranje proizvoda
                const product = {
                    id: target.parentElement.children[4].dataset.id,
                    naziv: target.parentElement.children[4].dataset.naziv,
                    proizvodjac: target.parentElement.children[4].dataset.proizvodjac,
                    model: target.parentElement.children[4].dataset.model,
                    cena: target.parentElement.children[4].dataset.cena,
                    cenaNaPopustu: target.parentElement.children[4].dataset.cena_na_popustu,
                    sezona:target.parentElement.children[4].dataset.sezona,
                    sirina: target.parentElement.children[4].dataset.sirina,
                    visina: target.parentElement.children[4].dataset.visina,
                    precnik: target.parentElement.children[4].dataset.precnik,
                    slika: target.parentElement.children[4].dataset.slika
                }
                
                try {
                    await axios({
                        method: 'post',
                        url: `/cart`,
                        data: {
                            product,
                            addQuantity: oldQuantity * -1
                        }
                    });
                    location.assign(url);
                } catch(err) {
                    console.log(err);
                }
            }
        });

    }
    
}


export function whatShows(){
    const whoPaysBtns = document.querySelectorAll('.radio-group--who-pays input');
    const pibBtn = document.querySelector('.billing-details-form input:first-child');
    const companyBtn = document.querySelector('.billing-details-form #kompanija');
    const imeBtn = document.getElementById('ime');
    // const telBtn = document.getElementById('tel');
    // const adresaBtn = document.getElementById('adresa');
    // const postaBtn = document.getElementById('posta');
    // const emailBtn = document.getElementById('email');

    // Dugmici za nacin placanja
    const pouzecePaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[2];
    const pouzecePaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[2];
    const cardPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[3];
    const cardPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[3];
    const uplatnicaPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[0];
    const uplatnicaPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[0];
    const virmanPaymentBtn = document.querySelectorAll('.radio-group.radio-group--payment')[1];
    const virmanPaymentBtnInput = document.querySelectorAll('.radio-group.radio-group--payment input')[1];

    // Dugmici za isporuku
    const gratisDeliveryBtn = document.querySelectorAll('.radio-group.radio-group--delivery')[0];
    const gratisDeliveryBtnInput = document.querySelectorAll('.radio-group.radio-group--delivery input')[0];
    const setInServisDeliveryBtn = document.querySelectorAll('.radio-group.radio-group--delivery')[1];
    const setInServisDeliveryBtnInput = document.querySelectorAll('.radio-group.radio-group--delivery input')[1];
    const setOnFieldDeliveryBtn = document.querySelectorAll('.radio-group.radio-group--delivery')[2];
    // const setOnFieldDeliveryBtnInput = document.querySelectorAll('.radio-group.radio-group--delivery input')[2];
    // console.log(pouzecePaymentBtn); //

    // Summary table
    let productsAmount = document.querySelector('.summary-table--products');
    let isporuka = document.querySelector('.summary-table__field');
    // let izlazakNaTeren = document.querySelector('.summary-table--field');
    let montazaNaTerenu = document.querySelector('.summary-table--setting-on-field');
    let ukupnoZaPlacanje = document.querySelector('.summary-table--total-price');


    // Placanje preko firme
    whoPaysBtns[1].addEventListener('click', () => {
        pibBtn.setAttribute('style', 'display: block');
        companyBtn.setAttribute('style', 'display: block');

        virmanPaymentBtn.style.display = 'block';
        uplatnicaPaymentBtn.setAttribute('style', 'display: none');
        pouzecePaymentBtn.setAttribute('style', 'display: none');
        cardPaymentBtn.setAttribute('style', 'display: none');
    });

    // Placanje kao fizicko lice
    whoPaysBtns[0].addEventListener('click', () => {
        pibBtn.setAttribute('style', 'display: none');
        companyBtn.setAttribute('style', 'display: none');

        virmanPaymentBtn.style.display = 'none';
        uplatnicaPaymentBtn.setAttribute('style', 'display: block');
        pouzecePaymentBtn.setAttribute('style', 'display: block');
        cardPaymentBtn.setAttribute('style', 'display: block');
    }); 

    // Nacini placanja
    pouzecePaymentBtnInput.addEventListener('click', () => gratisDeliveryBtn.style.display = 'block');
    // cardPaymentBtnInput.addEventListener('click', () => gratisDeliveryBtn.style.display = 'block'); // ⏳ Enable when Allsecure deal is done


    // Nacini isporuke -----------------
    gratisDeliveryBtnInput.addEventListener('click', () => {
        // izlazakNaTeren.dataset.amount = 0;
        // izlazakNaTeren.textContent = `${(0).toLocaleString()} rsd`;

        montazaNaTerenu.dataset.amount = 0;
        montazaNaTerenu.textContent = `0 rsd`;

        ukupnoZaPlacanje.dataset.amount = productsAmount.dataset.amount * 1 /* + izlazakNaTeren.dataset.amount * 1 */ + montazaNaTerenu.dataset.amount * 1;
        ukupnoZaPlacanje.textContent = `${(ukupnoZaPlacanje.dataset.amount * 1).toLocaleString()} rsd`;
    });


    setInServisDeliveryBtnInput.addEventListener('click', () => {
        // izlazakNaTeren.dataset.amount = 0;
        // izlazakNaTeren.textContent = `${(0).toLocaleString()} rsd`;

        montazaNaTerenu.dataset.amount = montazaNaTerenu.dataset.montaza;
        montazaNaTerenu.textContent = `${(montazaNaTerenu.dataset.montaza).toLocaleString()} rsd`;

        ukupnoZaPlacanje.dataset.amount = productsAmount.dataset.amount * 1 /* + izlazakNaTeren.dataset.amount * 1 */ + montazaNaTerenu.dataset.amount * 1;
        ukupnoZaPlacanje.textContent = `${(ukupnoZaPlacanje.dataset.amount * 1).toLocaleString()} rsd`;
    });


    // setOnFieldDeliveryBtnInput.addEventListener('click', () => {
    //     izlazakNaTeren.dataset.amount = 2000;
    //     izlazakNaTeren.textContent = `${(2000).toLocaleString()} rsd`;

        // montazaNaTerenu.dataset.amount = montazaNaTerenu.dataset.montaza;
        // montazaNaTerenu.textContent = `${(montazaNaTerenu.dataset.montaza).toLocaleString()} rsd`;

        // ukupnoZaPlacanje.dataset.amount = productsAmount.dataset.amount * 1 + izlazakNaTeren.dataset.amount * 1 + montazaNaTerenu.dataset.amount * 1;
        // ukupnoZaPlacanje.textContent = `${(ukupnoZaPlacanje.dataset.amount * 1).toLocaleString()} rsd`;
    // });
    // ------------------------------


    


}


export async function collectPurchaseData(){
    const cart = [];
    let item = {}
    let productItems = document.getElementsByClassName('main-table__product-row');

    let productsTotalPrice;
    let delivery;
    let totalPrice;
    // let izlazakNaTeren;
    let montaza;

    const orderBtn = document.querySelector('.section-billing-details .confirm-button');

    let whoPays;
    let selectedPayment;
    let acceptanceMethod;

    let pib;
    let company;
    let fullName;
    let phone;
    let address;
    let postCode;
    let email;
    let message;


    const collectData = async () => {

        // 1 - Prikupljanje podataka

        // Prikupljanje proizvoda u korpi u varijablu cart
        for (let i = 0; i < productItems.length; i++) {
            item = {
                name: productItems[i].children[2].textContent,
                price: productItems[i].children[3].textContent,
                quantity: productItems[i].children[4].children[1].textContent,
                subtotal: productItems[i].children[5].textContent
            }
            cart.push(item);
        }
    
        productsTotalPrice = document.querySelector('.summary-table .summary-table__row').children[1].textContent;
        delivery = document.querySelector('.summary-table .summary-table--delivery').textContent;
        // izlazakNaTeren = document.querySelector('.summary-table .summary-table--field').textContent;
        montazaNaTerenu = document.querySelector('.summary-table .summary-table--setting-on-field').textContent;
        totalPrice = document.querySelector('.summary-table .summary-table--total-price').textContent;


        if(document.querySelector('.radio-group--who-pays input[type="radio"]:checked')) {
            whoPays = document.querySelector('.radio-group--who-pays input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite ko plaća! Fizičko lice ili firma?') }

        if(document.querySelector('.radio-group--payment input[type="radio"]:checked')) {
            selectedPayment = document.querySelector('.radio-group--payment input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način plaćanja') }

        if(document.querySelector('.radio-group--delivery input[type="radio"]:checked')){
            acceptanceMethod = document.querySelector('.radio-group--delivery input[type="radio"]:checked').dataset.content;
        } else { return showAlert('error', 'Izaberite način preuzimanja') }
        
        // Mozda se pib i kompanije ne pojave u zavisnosti placa li fizicko ili pravno lice 🔑
        if (document.getElementById('pib').style.display == 'block'){
            pib = document.getElementById('pib').value;
            if(!pib) return showAlert('error', 'Unesite PIB firme');
        }
        if (document.getElementById('kompanija').style.display == 'block'){
            company = document.getElementById('kompanija').value;
            if(!company) return showAlert('error', 'Unesite naziv firme');
        }

        fullName = document.getElementById('ime').value;
        if(!fullName) return showAlert('error', 'Unesite ime');

        phone = document.getElementById('tel').value;
        if(!phone) return showAlert('error', 'Unesite telefon');

        address = document.getElementById('adresa').value;
        if(!address) return showAlert('error', 'Unesite adresu');

        postCode = document.getElementById('posta').value;
        if(!postCode) return showAlert('error', 'Unesite poštanski broj');

        email = document.getElementById('email').value;
        if(!email) return showAlert('error', 'Unesite email');

        message = document.getElementById('message').value;

        // console.log('🏈 whoPays: ', whoPays); //
        // console.log('🏈 selectedPayment: ', selectedPayment); //
        // console.log('🏈 acceptanceMethod: ', acceptanceMethod); //

        // Kontrola loseg unosa
        if(whoPays === 'Plaćam kao fizičko lice' && selectedPayment === 'Virman - pravna lica'){
            return showAlert('error', 'Izaberite način plaćanja');
        }
        if(whoPays === 'Plaćam preko firme' && selectedPayment !== 'Virman - pravna lica'){
            return showAlert('error', 'Izaberite način plaćanja');
        }

        // Ako placa:
        // - fizicko lice pouzecem ili uplatnicom,
        // - pravno lice virmanski
        if(selectedPayment !== 'Online plaćanje platnim karticama'){
            // 2 - Slanje emaila ka kupcu i prodavcu
            try{
                const successfullBooking = await axios({
                    url: '/booking/emails',
                    method: 'post',
                    data: {
                        pib,
                        company,
                        fullName,
                        email,
                        address,
                        postCode,
                        phone,
                        message,
                        delivery,
                        productsTotalPrice,
                        // izlazakNaTeren,
                        montazaNaTerenu,
                        totalPrice,
                        whoPays,
                        acceptanceMethod,
                        selectedPayment
                    }
                });
                // console.log('🏀 successfullBooking:', successfullBooking);

                if(successfullBooking.status == 200){
                    showAlert('success', 'Uspešna porudžbina. Proverite Vaš email');
        
                    // 3 - Brisanje korpe
                    await axios({
                        url: '/cart',
                        method: 'delete'
                    });
        
                    // 4 - Usmeravanje na zeljenu stranu
                    window.setTimeout(() => location.assign('/'), 5 * 1000); //
                }
            } catch(err){
                console.log('🏀 err:', err);
            }
        // Ako se placa karticom
        } else {
            // 1 - Ubacivanje podataka kupca u req.session.buyerData
            try{
                var res = await axios({
                    url: '/cart/buyer',
                    method: 'post',
                    data: {
                        fullName,
                        email,
                        address,
                        postCode,
                        phone,
                        message,
                        delivery,
                        productsTotalPrice,
                        // izlazakNaTeren,
                        montazaNaTerenu,
                        totalPrice,
                        whoPays,
                        acceptanceMethod,
                        selectedPayment
                    }
                });
                if(res.status == 201) showAlert('success', 'Uspešno slanje podataka. Bićete usmereni na stranicu za unos podataka o kartici.');

            } catch(err) {
                showAlert('error', 'Došlo je do greške, pokušajte ponovo!');
            }

            // 2 - Usmeravanje na stranu koja aktivira zahtev ka All-secure, korak priprema checkout-a
            window.setTimeout(() => location.assign('/porucivanje/placanje'), 3 * 1000);
        }
        
      
    }


    orderBtn.addEventListener('click', collectData);

}