export function map(){
    // Cerak
    const latC = 44.75117540736682;
    const lngC = 20.427038698500045;

    const optionsC = {
        title: 'Cerak'
    }

    // Zeleznik
    const latZ = 44.73423173940253;
    const lngZ = 20.376436683216106;

    const optionsZ = {
        title: 'Železnik'
    }

    // Making a map and tile
    const mymap = L.map('map', {
        scrollWheelZoom: false
    }).setView([latC, lngC], 12);

    const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
    const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?';
    const tiles = L.tileLayer(tileUrl, { attribution });

    // Make a marker with a custom icon
    // var myIcon = L.icon({
    //     iconUrl: '/recources/img/tango5-logo.webp',
    //     iconSize: [30, 30],
    //     iconAnchor: [15, 15]
    // });

    tiles.addTo(mymap);
    const markerCerak = L.marker([latC, lngC], optionsC).addTo(mymap);
    const markerZeleznik = L.marker([latZ, lngZ], optionsZ).addTo(mymap);
    // const marker = L.marker([lat, lng], {icon: myIcon}).addTo(mymap);

    // var circle = L.circle([latZ, lngZ], {
    //     color: 'brown',
    //     fillColor: '#e4b06c',
    //     fillOpacity: 0.5,
    //     radius: 50
    // }).addTo(mymap);
}