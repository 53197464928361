export default function mobMenu(){
    // Selekcija
    const iconOpen = document.querySelector('.nav-icon__menu--open');
    const iconClose = document.querySelector('.nav-icon__menu--close');
    const navBox = document.querySelector('.header__link-box');

    [iconOpen, iconClose].forEach(icon => {
        icon.addEventListener('click', function(e){
            const target = e.target.closest('.nav-icon__menu');
            
            if(target.classList == 'nav-icon__menu nav-icon__menu--open'){
                // 1 - Prikaz menija
                navBox.setAttribute('style', 'display:block');
    
                // 2 - Promena ikone
                iconOpen.setAttribute('style', 'display: none');
                iconClose.setAttribute('style', 'display: block');

            } else if(target.classList == 'nav-icon__menu nav-icon__menu--close') {
                // 1 - Sakrivanje menija
                navBox.setAttribute('style', 'display:none');
    
                // 2 - Promena ikone
                iconClose.setAttribute('style', 'display: none');
                iconOpen.setAttribute('style', 'display: block');
            }

        });
    });
}