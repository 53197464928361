export const getConfiguratorString = (sir, vis, prec, sez, proizv) => {
    const sirinaBtn = document.getElementById('sirina');
    const visinaBtn = document.getElementById('visina');
    const precnikBtn = document.getElementById('precnik');
    const sezonaBtn = document.getElementById('sezona');
    const proizvodjacBtn = document.getElementById('proizvodjac');
    const confirmBtn = document.querySelector('.confirm-button--tyres');

    let sirina;
    let visina;
    let precnik;
    let sezona;
    let proizvodjac;

    let urlString = '';

    sirinaBtn.addEventListener('change', function(e){
        sirina = sirinaBtn.options[sirinaBtn.selectedIndex].value;
        // console.log(sirina); //
    });
    
    visinaBtn.addEventListener('change', function(e){
        visina = visinaBtn.options[visinaBtn.selectedIndex].value;
        // console.log(visina); //
    });

    precnikBtn.addEventListener('change', function(e){
        precnik = precnikBtn.options[precnikBtn.selectedIndex].value;
        // console.log(precnik); //
    });

    sezonaBtn.addEventListener('change', function(e){
        sezona = sezonaBtn.options[sezonaBtn.selectedIndex].value;
    });

    proizvodjacBtn.addEventListener('change', function(e){
        proizvodjac = proizvodjacBtn.options[proizvodjacBtn.selectedIndex].value.toLowerCase();
        // console.log(proizvodjac); //
    });

    // return;

    confirmBtn.addEventListener('click', function(e){
        e.preventDefault();

        if (!sirina && !visina && !precnik && !sezona && !proizvodjac) return;
        if (sirina || visina || precnik || sezona || proizvodjac ) urlString = '?';

        if (sirina) urlString = urlString + 'sirina=' + sirina + ' ';
        if (visina) urlString = urlString + 'visina=' + visina + ' ';
        if (precnik) urlString = urlString + 'precnik=' + precnik + ' ';
        if (sezona) urlString = urlString + 'sezona=' + sezona + ' ';
        if (proizvodjac) urlString = urlString + 'proizvodjac=' + proizvodjac + ' ';

        urlString = urlString.replace(/ /g, '&' );
        // 🧩 BF_Goodrich bez razmaka, zbog formiranja url-a
        urlString = urlString.replaceAll('_', ' '); 
        urlString = urlString.slice(0, -1);
        // console.log('🏀 urlString:', urlString);

        location.href = `${location.href.split('/')[0]}/gume${urlString}`;
    });
    
}