// import axios from 'axios';

import { showAlert } from './alert.js';



export async function buyWithCard() {

    const paymentSuccessfull = document.getElementById('paymentSuccessfull');
    if(!paymentSuccessfull) return;

    const buyer = JSON.parse(document.getElementById('paymentSuccessfull').dataset.buyerdata);
    // console.log('🏈 buyer: ', buyer);
    // console.log(allSecureResponse);
    
    try {
        
        // 1 - Slanje emaila ka kupcu, kao i ka prodavcu
        // console.log('🏈 sending emails: ');

        await axios({
            url: '/booking/emails',
            method: 'post',
            data: {
                email: buyer.email,
                fullName: buyer.fullName,
                address: buyer.address,
                postCode: buyer.postCode,
                phone: buyer.phone,
                message: buyer.message,
                productsTotalPrice: buyer.productsTotalPrice,
                totalPrice: buyer.totalPrice,
                delivery: buyer.delivery,
                acceptanceMethod: buyer.acceptanceMethod,
                selectedPayment: buyer.selectedPayment,
                izlazakNaTeren: buyer.izlazakNaTeren,
                montazaNaTerenu: buyer.montazaNaTerenu,
                whoPays: buyer.whoPays
            }
        });

        // 2 - Brisanje korpe (req.session.cart)
        await axios({
            url: '/cart',
            method: 'delete'
        });

        // Brisanje req.session.buyerData
        await axios({
            url: '/cart/buyer',
            method: 'delete'
        });

        showAlert('success', 'Uspešna porudžbina. Pozvaćemo Vas uskoro!');

        // 3 - Usmeravanje na zeljenu stranu
        window.setTimeout(() => location.assign('/'), 3 * 1000);

    } catch(err){
        console.log(err);
    }
}