import { showAlert } from './alert.js';

export async function login(){
    // console.log('🏀 login f-ja: '); //

    document.querySelector('.confirm-button').addEventListener('click', async function(e) {
        // console.log('🏀 klik: '); //
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('pass').value;
        // return console.log('🏀 email, password: ', email, password); //

        try{
            const res = await axios({
                method: 'post',
                url: '/api/v1/users/login',
                data: {
                    email: email,
                    password: password
                }
            });
        
            // console.log('🏀 res: ',res); //
            location.assign('/admin');
    
        } catch(err){
            showAlert('error', err.response.data.message);
        }
    });
}


export const logout = async function(){
    const logoutBtn = document.querySelector('.header__logout');
    if(!logoutBtn) return;

    logoutBtn.addEventListener('click', async function(e){
        e.preventDefault();

        try {
            var res = await axios({
                method: 'GET',
                url: '/api/v1/users/logout'
            });
    
            if(res.data.status === 'success') {
                location.assign('/');
            }
    
        } catch (err){
            showAlert('err', 'Greška u odjavljivanju, pokušajte ponovo.');
        }
    });
}