// import { async } from 'regenerator-runtime';
import { showAlert } from './alert.js';


function collectData(){
    let brand;
    let model;
    let godiste;
    let motor;
    let precnik;
    let email;
    let tel;

    brand = document.getElementById('proizvodjac').value;
    if(!brand) return showAlert('error', 'Unesite brend auta');

    model = document.getElementById('model').value;
    if(!model) return showAlert('error', 'Unesite model auta');

    godiste = document.getElementById('godiste').value;
    if(!godiste) return showAlert('error', 'Unesite godište auta');

    motor = document.getElementById('motor').value;
    if(!motor) return showAlert('error', 'Unesite motor auta');

    precnik = document.getElementById('precnikFelne').value;
    if(!precnik) return showAlert('error', 'Unesite prečnik felne');

    email = document.getElementById('email').value;

    tel = document.getElementById('tel').value;
    if(!tel) return showAlert('error', 'Unesite Vaš broj telefona');

    return {
        brand,
        model,
        godiste,
        motor,
        precnik,
        email,
        tel
    };

}


async function sendData(obj){
    try {
        const res = await axios({
            url: '/solidWheelSubmit',
            method: 'post',
            data: obj
        });
        // console.log('🏀 res:' ,res);
        if(res.status == 200){
            showAlert('success', 'Uspešno ste poslali upit. Javićemo Vam se uskoro.');
        }
        window.setTimeout(() => location.assign('/'), 5 * 1000);
    } catch (err) {
        console.log('🏀 err:', err);
    }
}


async function sendForm(){
    document.querySelector('.confirm-button').addEventListener('click', async function(){
        const formData = collectData();
        if(formData === undefined) return;
        
        await sendData(formData);
    });
}


export default sendForm;